import React, { Suspense } from 'react';

import { ConstitutionSection } from '../../index';

const Article1 = React.lazy(() => import('./Article1'));
const Article2 = React.lazy(() => import('./Article2'));
const Article3 = React.lazy(() => import('./Article3'));
const Article4 = React.lazy(() => import('./Article4'));
const Article5 = React.lazy(() => import('./Article5'));
const Article6 = React.lazy(() => import('./Article6'));
const Article7 = React.lazy(() => import('./Article7'));
const Article8 = React.lazy(() => import('./Article8'));
const Article9 = React.lazy(() => import('./Article9'));

const ConstitutionAndLaws: ConstitutionSection = {
  title: 'Constitution & Laws',
  revised: 'October 1, 2024',
  articles: [
    {
      name: 'Article 1',
      content: (
        <Suspense fallback={<></>}>
          <Article1 />
        </Suspense>
      ),
    },
    {
      name: 'Article 2',
      content: (
        <Suspense fallback={<></>}>
          <Article2 />
        </Suspense>
      ),
    },
    {
      name: 'Article 3',
      content: (
        <Suspense fallback={<></>}>
          <Article3 />
        </Suspense>
      ),
    },
    {
      name: 'Article 4',
      content: (
        <Suspense fallback={<></>}>
          <Article4 />
        </Suspense>
      ),
    },
    {
      name: 'Article 5',
      content: (
        <Suspense fallback={<></>}>
          <Article5 />
        </Suspense>
      ),
    },
    {
      name: 'Article 6',
      content: (
        <Suspense fallback={<></>}>
          <Article6 />
        </Suspense>
      ),
    },
    {
      name: 'Article 7',
      content: (
        <Suspense fallback={<></>}>
          <Article7 />
        </Suspense>
      ),
    },
    {
      name: 'Article 8',
      content: (
        <Suspense fallback={<></>}>
          <Article8 />
        </Suspense>
      ),
    },
    {
      name: 'Article 9',
      content: (
        <Suspense fallback={<></>}>
          <Article9 />
        </Suspense>
      ),
    },
  ],
};

export default ConstitutionAndLaws;
