import { Box } from '@chakra-ui/react';
import { graphql, PageProps } from 'gatsby';
import React from 'react';

import { ConstitutionQuery } from '../../../graphql-types';
import Constitution from '../../components/Constitution';
import Layout from '../../components/Layout';
import PageIdentifier from '../../components/PageIdentifier';
import {
  WLBreadcrumb,
  WLButton,
  WLContainer,
  WLH1V2,
  WLH2,
  WLH2V2,
  WLTextV2,
} from '../../design-library';
import ContentfulUtils from '../../utils/contentful.utils';

interface ConstitutionPageProps extends PageProps<ConstitutionQuery> {}

const ConstitutionPage: React.FC<ConstitutionPageProps> = ({
  data,
  location,
}) => {
  const constitutionPdfLink = ContentfulUtils.getAssetInfo(
    data.contentfulAsset,
  )?.url;

  return (
    <Layout
      seo={{ title: 'Constitution & Laws' }}
      currentPath={location.pathname}
    >
      <WLContainer
        width="EXTRA_WIDE"
        align="start"
        paddingX={{ base: 4, lg: 8 }}
        paddingTop={{ base: 2, lg: 2 }}
        paddingBottom={{ base: 3, lg: 3 }}
      >
        <WLBreadcrumb
          entries={[
            { label: 'Home', link: '/' },
            { label: 'About', link: '/about/' },
            { label: 'Constitution' },
          ]}
        />
      </WLContainer>
      <WLContainer width="WIDE" paddingTop={4} paddingX={{ base: 2, md: 0 }}>
        <WLH1V2
          _before={{
            content: '""',
            display: 'block',
            width: '32px',
            height: '4px',
            borderRadius: '1px',
            margin: '0 auto',
            marginBottom: { base: '20px', md: '24px' },
            backgroundColor: '#EA002A',
          }}
        >
          Constitution & Laws
        </WLH1V2>
        <Box
          paddingY={4}
          display="flex"
          flexDirection={'column'}
          justifyContent={'center'}
          gap="1rem"
        >
          <WLTextV2 textAlign={'center'}>
            The WoodmenLife Articles of Incorporation and Constitution and Laws
            describe how WoodmenLife is governed, its purposes and objectives as
            well as the rights of our members.
          </WLTextV2>
          {constitutionPdfLink && (
            <WLButton
              uppercase
              colorScheme="blue"
              variant="solid"
              href={constitutionPdfLink}
              width="fit-content"
              margin="0 auto"
            >
              Download a PDF Version
            </WLButton>
          )}
        </Box>

        <Box paddingY={4}>
          <WLH2V2 size="xl" textAlign={'center'}>
            Woodmen of the World Life Insurance Society
          </WLH2V2>
          <WLTextV2 textAlign={'center'} mb={{ base: '24px', lg: '48px' }}>
            The Woodmen of the World Life Insurance Society Articles of
            Incorporation and Constitution and Laws are part of your benefit
            certificate. (See Constitution and Laws, Article 6.) They describe
            how Woodmen of the World Life Insurance Society is governed, its
            purposes and objectives, as well as your rights as members.
          </WLTextV2>

          <Constitution />
        </Box>
      </WLContainer>

      <WLContainer width="WIDE" align="start" paddingX={{ base: 3, xl: 0 }}>
        <PageIdentifier webNumber="Web 6" lastUpdated="10/01/2024" />
      </WLContainer>
    </Layout>
  );
};

export const pageQuery = graphql`
  query Constitution {
    contentfulAsset(title: { eq: "Constitution" }) {
      file {
        url
      }
      localFile {
        publicURL
      }
    }
  }
`;

export default ConstitutionPage;
