import React, { Suspense } from 'react';

import { WLText } from '../../../../design-library';
import { ConstitutionSection } from '../../index';

const Article1 = React.lazy(() => import('./Article1'));
const Article2 = React.lazy(() => import('./Article2'));
const Article3 = React.lazy(() => import('./Article3'));
const Article4 = React.lazy(() => import('./Article4'));
const Article5 = React.lazy(() => import('./Article5'));
const Article6 = React.lazy(() => import('./Article6'));

const ChapterBylaws: ConstitutionSection = {
  title: 'Chapter Bylaws',
  revised: 'January 1, 2023',
  commonIntro: (
    <WLText as="b">
      The following Bylaws are prescribed for the Governance of WoodmenLife
      chapters. The term “chapters” shall include those subordinate bodies
      described in Article 8 of WoodmenLife\'s Articles of Incorporation. The
      blank spaces should be filled, not inconsistent with the Constitution and
      Laws of WoodmenLife, and may be adopted by chapters subject to the
      approval of the President & CEO of WoodmenLife. Any language in
      parentheses ( ) is optional and may or may not be incorporated into the
      Bylaws of a Chapter.
    </WLText>
  ),
  articles: [
    {
      name: 'Article 1',
      content: (
        <Suspense fallback={<></>}>
          <Article1 />
        </Suspense>
      ),
    },
    {
      name: 'Article 2',
      content: (
        <Suspense fallback={<></>}>
          <Article2 />
        </Suspense>
      ),
    },
    {
      name: 'Article 3',
      content: (
        <Suspense fallback={<></>}>
          <Article3 />
        </Suspense>
      ),
    },
    {
      name: 'Article 4',
      content: (
        <Suspense fallback={<></>}>
          <Article4 />
        </Suspense>
      ),
    },
    {
      name: 'Article 5',
      content: (
        <Suspense fallback={<></>}>
          <Article5 />
        </Suspense>
      ),
    },
    {
      name: 'Article 6',
      content: (
        <Suspense fallback={<></>}>
          <Article6 />
        </Suspense>
      ),
    },
  ],
};

export default ChapterBylaws;
