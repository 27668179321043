import React, { Suspense } from 'react';

import { ConstitutionSection } from '../../index';

const Content = React.lazy(() => import('./Content'));

const EstablishedJurisdictions: ConstitutionSection = {
  title: 'Established Jurisdictions WoodmenLife',
  revised: 'January 1, 2023',
  content: (
    <Suspense fallback={<></>}>
      <Content />
    </Suspense>
  ),
};

export default EstablishedJurisdictions;
