import React, { Suspense } from 'react';

import { WLText } from '../../../../design-library';
import { ConstitutionSection } from '../../index';
const Article1 = React.lazy(() => import('./Article1'));
const Article2 = React.lazy(() => import('./Article2'));
const Article3 = React.lazy(() => import('./Article3'));
const Article4 = React.lazy(() => import('./Article4'));
const Article5 = React.lazy(() => import('./Article5'));
const Article6 = React.lazy(() => import('./Article6'));
const Article7 = React.lazy(() => import('./Article7'));

const JurisdictionalBylaws: ConstitutionSection = {
  title: 'Jurisdictional Bylaws',
  revised: 'January 1, 2023',
  commonIntro: (
    <WLText as="b">
      (The following are rules which prescribe the governance of Jurisdictions
      of WoodmenLife and should be adopted as provisions of the Bylaws of the
      Jurisdiction.)
    </WLText>
  ),
  articles: [
    {
      name: 'Article 1',
      content: (
        <Suspense fallback={<></>}>
          <Article1 />
        </Suspense>
      ),
    },
    {
      name: 'Article 2',
      content: (
        <Suspense fallback={<></>}>
          <Article2 />
        </Suspense>
      ),
    },
    {
      name: 'Article 3',
      content: (
        <Suspense fallback={<></>}>
          <Article3 />
        </Suspense>
      ),
    },
    {
      name: 'Article 4',
      content: (
        <Suspense fallback={<></>}>
          <Article4 />
        </Suspense>
      ),
    },
    {
      name: 'Article 5',
      content: (
        <Suspense fallback={<></>}>
          <Article5 />
        </Suspense>
      ),
    },
    {
      name: 'Article 6',
      content: (
        <Suspense fallback={<></>}>
          <Article6 />
        </Suspense>
      ),
    },
    {
      name: 'Article 7',
      content: (
        <Suspense fallback={<></>}>
          <Article7 />
        </Suspense>
      ),
    },
  ],
};

export default JurisdictionalBylaws;
