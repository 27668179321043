import { ConstitutionSection } from '../index';
import ArticlesOfIncorporation from './ArticlesOfIncorporation';
import ChapterBylaws from './ChapterBylaws';
import ConstitutionAndLaws from './ConstitutionAndLaws';
import Duties from './Duties';
import EstablishedJurisdictions from './EstablishedJurisdictions';
import JurisdictionalBylaws from './JurisdictionalBylaws';
import RulesOfOrder from './RulesOfOrder';

const Sections: ConstitutionSection[] = [
  ArticlesOfIncorporation,
  ConstitutionAndLaws,
  ChapterBylaws,
  JurisdictionalBylaws,
  EstablishedJurisdictions,
  RulesOfOrder,
  Duties,
];

export default Sections;
