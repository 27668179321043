import React, { Suspense } from 'react';

import { ConstitutionSection } from '../../index';
const Content = React.lazy(() => import('./Content'));

const RulesOfOrder: ConstitutionSection = {
  title: 'Duties of Officers',
  revised: 'January 1, 2023',
  content: (
    <Suspense fallback={<></>}>
      <Content />
    </Suspense>
  ),
};

export default RulesOfOrder;
